@keyframes rotate {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
@keyframes rarity {
  0% {
    left: -60000px;
  }
  100% {
    left: 60000px;
  }
}
html, body {
  height: 100%;
  overflow: hidden;
}

.rotate {
  animation: rotate 3s linear infinite;
}

body {
  overflow-x: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

#face {
  font-size: 128px;
  font-weight: bold;
  line-height: 128px;
  text-align: center;
  transform: rotate(0deg);
}

#note {
  z-index: 1002;
  cursor: default;
  font-size: 12px;
  opacity: 0.5;
  position: absolute;
  bottom: 0;
  right: 0;
  padding: 16px 8px 8px 16px;
  text-align: right;
  background: white;
  border-top-left-radius: 100%;
  transition: opacity 1s;
  overflow: none;
}
#note:hover {
  opacity: 1;
}
#note a {
  text-decoration: none;
}
#note a:hover {
  text-decoration: underline;
}

#background {
  display: none;
  height: 100%;
  left: 0;
  min-height: 100%;
  min-width: 100%;
  position: absolute;
  top: 0;
  width: 100%;
  z-index: -999;
}

#rarity {
  animation: rarity 120s linear;
  background: url("rarity.png") no-repeat;
  bottom: 0px;
  height: 449px;
  left: -10000px;
  position: absolute;
  width: 658px;
}

.cage {
  position: absolute;
}

#undertale {
  box-sizing: border-box;
  padding: 20px 13px;
  background: black;
  color: white;
  font-family: "8bitoperator JVE Regular";
  font-smooth: never;
  font-size: 32px;
  line-height: 1;
  border: 5px solid white;
  box-shadow: 0 0 0 5px black;
  padding-left: 13px;
  width: 580px;
  height: 154px;
  position: fixed;
  bottom: 40px;
  left: 50%;
  transform: translate(-50%, 0);
  tab-size: 34px;
  z-index: 1001;
}

.hidden {
  display: none;
}
